.ActionsSkeletonWrapper {
  height: 130px;

  padding-bottom: 50px;

  display: flex;

  &>div {
    width: 273px;
    margin-right: 25px;

    &:last-child {
      width: 80px;
    }
  }
}